header {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50px);
  display: flex;
  justify-content: space-between;
  width: 1280px;
  margin: 0 auto;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #a9a8a8;
  p {
    position: relative;
    display: flex;
    color: white;
    text-align: left;
    &.address {
      width: 290px;
      img {
        padding-right: 5px;
      }
    }

    &.tel1 {
      width: 220px;
      justify-content: space-between;
      span:nth-child(2) {
        display: flex;
        flex-direction: column;
      }
    }

    &.tel2 {
      align-items: center;
      img {
        padding-right: 5px;
      }
    }

    &.email {
      align-items: center;
      img {
        padding-right: 5px;
      }
    }

    a {
      color: white;
      text-decoration: none;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  header {
    width: 95vw;
  }
}

@media (min-width: 320px) and (max-width: 1140px) {
  header {
    p {
      img {
        display: none;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  header {
    width: 100vw;
    padding: 20px;
    .email {
      display: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  header {
    justify-content: center;
    .tel1,
    .tel2 {
      display: none;
    }
  }
}