.section-3 {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: #3986b2;
  p {
    width: 910px;
    font-size: 30px;
    color: white;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .section-3 {
    p {
      width: 90vw;
    }
  }
}