footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  padding: 100px 0;
  p {
    display: flex;
    // flex-direction: column;
    color: #828282;
    text-align: left;

    &.address {
      width: 290px;
      img {
        padding-right: 5px;
      }
    }
    
    &.tel1 {
      width: 220px;
      justify-content: space-between;
      span:nth-child(2) {
        display: flex;
        flex-direction: column;
      }
    }

    &.tel2 {
      align-items: center;
      img {
        padding-right: 5px;
      }
    }

    &.email {
      align-items: center;
      img {
        padding-right: 5px;
      }
    }

    a {
      color: #828282;
      text-decoration: none;
    }
  }
}

// @media (min-width: 1145px) and (max-width: 1279px) {
@media (min-width: 1025px) and (max-width: 1279px) {
  footer {
    width: 95vw;
  }
}

@media (min-width: 1025px) and (max-width: 1140px) {
  footer {
    p {
      img {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  footer {
    width: 100vw;
    padding: 20px;
    p {
      img {
        display: none;
      }
    }

    .email {
      display: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  footer {
    width: 100vw;
    flex-direction: column;
    padding: 50px 0;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}